@import "../../node_modules/antd/dist/antd.css";
@import "../../node_modules/react-table/react-table.css";

@import "./bootstrap.min.css";
@import "./fontawesome-all.min.css";
@import "./animate.css";

@import "./override-antd";
@import "./blue.css";
@import "./style.css";

body {
  font-family: 'Roboto',sans-serif !important;
  font-weight: 400;
}
.app-body{
  a:focus, a:hover {
    text-decoration: none !important;
  }
  .ant-modal-confirm-content{
    margin-left: 0 !important;
  }
  .app-sider{
    .menu-item{
      a{
        display: flex;
        align-items: center;
      }
    }
  }
  .drawer-avatar{
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-avatar-string{
      transform: none !important;
      position: static;
    }
  }
  .ant-card-extra{
    .button_container > .btn-toolbar  {
      >button, >div{
        margin-left: .5rem;
      }
    }
  }
  .ReactTable {
    .rt-thead.-header{
      box-shadow: none !important;
    }
  }
  .board-item {
    margin-right: 10px;
    margin-bottom: 10px
  }
  .board-list{
    display: inline;
  }
  .board-container {
    margin-bottom: 10px;
  }

}

@import './highschool-page';
@import './login-page';
@import './athletes-page';