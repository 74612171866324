.ant-notification {
  z-index: 9999999999999 !important;
}
.ant-select {
  width: -webkit-fill-available !important;
}
.ant-card{
  .ant-card-body {
    padding: 16px !important;
  }
  .ant-card-head{
    padding: 0 16px !important;
    min-height: 20px !important;
    .ant-card-head-wrapper{
      .ant-card-head-title{
        padding: 16px 0 !important;
      }
    }
  }
}
.ant-advanced-search-form {
  padding: 0;
}
.ant-advanced-search-form .ant-form-item {
  display: flex;
}
.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-modal-header,
.ant-modal-body,
.ant-modal-footer {
  padding: 10px !important;
}
.ant-btn>div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.anticon {
  vertical-align: middle !important;
}
.ant-form{
  .ant-row.ant-form-item{
    margin: 0 !important;
  }
}
.ant-layout-content{
  .ant-breadcrumb .ant-breadcrumb-link{
    font-size: 16px;
  }
  .ant-breadcrumb > span:last-child{
    font-weight: 500 !important;
  }
}
.ant-spin-nested-loading{
  .ant-spin.ant-spin-spinning{
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: none;
  }
}
