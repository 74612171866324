.athlete-grid-checkbox {
  .ant-checkbox-inner {
    border: 1px solid #1890ff;
  }
}
.merge-athlete-modal {
  .ant-modal {
    width: 90vw !important;
  }
  .ant-modal-content {
    .ant-modal-body {
      overflow: auto;
      max-height: 75vh;
      .ant-btn {
        border: none;
        background-color: transparent;
        padding-left: 0;
        .ant-checkbox-wrapper {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .merge-table-wrapper {
    overflow: auto;
    max-height: 30vh;
  }
}

.merge-athlete-modal-success {
  width: 90vw !important;
}