.high_school_detail .ant-card-body {
  padding: 0 !important;
}
.high_school_detail .ant-spin-nested-loading.h-100 > .ant-spin-container,
.high_school_detail .ant-card.h-100 .ant-card-body {
  height: 100% !important;
}
.high_school_detail .tab-content,
.high_school_detail .tab-content .tab-pane.active,
.high_school_detail .tab-content .tab-pane.active .ant-spin-nested-loading,
.high_school_detail .tab-content .tab-pane.active .ant-spin-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.yearClassDelete {
  width: 20em;
  margin-top: 2rem;
}
